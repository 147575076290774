import React from 'react';
import './NotFound.css'; 
import logo from './../../Png/logo.webp'

function NotFound() {
  return (
    <div className="not-found-container">
      <img src={logo} alt="Logo" className="not-found-logo" />
      <h1 className="not-found-title">404 - Page Not Found</h1>
      <p className="not-found-message">The page you are looking for does not exist.</p>
      <a href="/" className="not-found-home-link">Go to Home</a>
      <a href="mailto:info@bluedatab.com" className="not-found-contact-link">
        <i className="fas fa-envelope"></i> Contact Us
      </a>
    </div>
  );
}

export default NotFound;
