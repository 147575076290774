import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import ReportItem from './ReportItem';
import './ReportList.css';
import './../../../style.css';
import { ReportAPI } from '../../../Api/ReportAPI';
import { UserContext } from '../../../Utilis';

const ReportList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [reportsPerPage] = useState(30);
    const [reports, setReports] = useState([]);
    const [reportOpened, setReportOpened] = useState(null);
    const { user: userProfile, setUser: setUserProfile } = useContext(UserContext);

    useEffect(() => {
        ReportAPI.getReports().then((res) => {
            if (res.data) {
                setReports(res.data.slice().reverse());
            }
        }).catch((ex) => {
            console.error(ex);
        });
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (order) => {
        setSortBy('name');
        setSortOrder(order);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const filteredReports = reports.filter(report => 
        report.videoName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedReports = [...filteredReports].sort((a, b) => {
        if (sortBy === 'name') {
            const nameA = a.videoName || '';
            const nameB = b.videoName || '';
            return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        } else if (sortBy === 'date') {
            const dateA = new Date(a.date || '1970-01-01');
            const dateB = new Date(b.date || '1970-01-01');
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        }
        return 0;
    });

    const indexOfLastReport = currentPage * reportsPerPage;
    const indexOfFirstReport = indexOfLastReport - reportsPerPage;
    const currentReports = sortedReports.slice(indexOfFirstReport, indexOfLastReport);

    const totalPages = Math.ceil(sortedReports.length / reportsPerPage);

    return (
        <div className="report-list-container">
            <div className="controls-container">
                <div className="search-container">
                    <div className="search-input-wrapper">
                        <input
                            type="text"
                            placeholder="Search for the reports..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="search-input"
                        />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
                    </div>
                </div>
                <div className="sort-container">
                    <p style={{ fontWeight: 'bold', color: 'white' }}>Sort</p>
                    <div className="sort-buttons">
                        {sortOrder !== 'asc' && (
                            <FontAwesomeIcon 
                                icon={faArrowUp} 
                                className="sort-icon" 
                                onClick={() => handleSortChange('asc')}
                            />
                        )}
                        {sortOrder !== 'desc' && (
                            <FontAwesomeIcon 
                                icon={faArrowDown} 
                                className="sort-icon" 
                                onClick={() => handleSortChange('desc')}
                            />
                        )}
                    </div>
                </div>
            </div>
            {currentReports.map((report, index) => (
                report.videoId && report.videoName ? (
                    <ReportItem 
                        key={index}
                        report={report}
                        setShowedReport={() => setReportOpened(report.Id)}
                        showReportId={reportOpened}
                    />
                ) : null
            ))}
            <div className="pagination">
                {Array.from({ length: totalPages }).map((_, index) => (
                    <button
                        key={index}
                        style={{ backgroundColor: (index + 1) === currentPage ? "#007bff" : "rgb(0 38 255)" }}
                        onClick={() => {
                            paginate(index + 1);
                            setReportOpened(null);
                        }}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ReportList;