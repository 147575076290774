import React, { useState } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import zxcvbn from 'zxcvbn'; // Importing password strength estimator library

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        const passwordStrength = zxcvbn(password);
        if (passwordStrength.score < 3) {
            setErrorMessage("Password is too weak. Please choose a stronger password.");
            return;
        }

        Axios.post(`http://localhost:3000/auth/reset-password/${token}`, { password })
            .then(response => {
                if (response.data.status) {
                    navigate('/login');
                    
                    alert("Password reset successful! Please login with your new password.");
                }
            })
            .catch(err => {
                if (err.response.status === 400) {
                    setErrorMessage("Invalid token or other client-side error occurred.");
                } else if (err.response.status === 500) {
                    setErrorMessage("Server-side error occurred. Please try again later.");
                } else {
                    setErrorMessage("An unexpected error occurred. Please try again later.");
                }
            });
    };

    return (
        <div className="sign-up-container">
            <form className="sign-up-form" onSubmit={handleSubmit}>
                <h2>Reset Password</h2>

                <label htmlFor="password">New Password:</label>
                <input
                    type="password"
                    placeholder="******"
                    onChange={(e) => setPassword(e.target.value)}
                />
                {errorMessage && <p className="error">{errorMessage}</p>}

                <button type="submit">Reset</button>
            </form>
        </div>
    );
}

export default ResetPassword;