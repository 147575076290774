import React, { useState } from 'react';
import { ServiceInfo } from "../../../Utilis";
import './AppSquare.css';

const AppSquare = ({ appName, onClick, isAccessible }) => {
    const [showMessage, setShowMessage] = useState(false);
    const [clicked, setClicked] = useState(false);

    const getIconPath = (appName) => {
        return require(`./${appName}/${appName}.png`);
    };

    const handleClick = () => {
        if (!isAccessible) {
            setShowMessage(true);
            setClicked(true);
            setTimeout(() => {
                setShowMessage(false);
                setClicked(false);
            }, 10000);
        } else {
            onClick(appName);
        }
    };

    const accessClass = isAccessible || clicked ? '' : 'blur';

    return (
        <div id={`${appName}-square`} className={`square ${accessClass}`} onClick={handleClick}>
            <div className="icon-container">
                <img src={getIconPath(appName)} alt={appName} className="app-icon" />
            </div>
            <div className="app-name">{ServiceInfo[appName].displayName}</div>
            {showMessage && (
                <div className="access-message">
                    You don't have the rights for this application. Please contact us if you are interested in this application.
                </div>
            )}
        </div>
    );
};

export default AppSquare;