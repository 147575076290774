import React from 'react';
import Slider from 'react-slick';
import "./slider.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FishUpload from '../FishUpload';
import Navbar from '../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';
import { FishsInfo } from '../Utils';
import { useParams } from 'react-router-dom';

function FishType(){
  const {vrsta}=useParams()
    const settings = {
        dots: true,
        infinite: FishsInfo[vrsta].areas.length<=1?false:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
      };
      
      const areas=FishsInfo[vrsta].areas
      const title=FishsInfo[vrsta].title
      const subtitle=FishsInfo[vrsta].subtitle
      const source=FishsInfo[vrsta].source
      const pcloud_id=FishsInfo[vrsta].pcloud_id
      return (
        <>
        <Navbar/>
        <div className="container-fluid py-5" id="testimonial">
            <div className="position-relative d-flex align-items-center justify-content-center">
            <h1 className="display-1 text-uppercase text-white">{title}</h1>
              {areas.length>0?<h1 className="position-absolute text-uppercase text-primary">{subtitle}</h1>:
              <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>{subtitle}</p>
}
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
              {areas.length>0?<Slider {...settings}>
      {areas.map((area, index) => {
          console.log(index)
        return <div key={index} className="testimonial-slide text-center">
          <div className="testimonial-content-wrapper">
            <img src={area.image} alt={area.name} className="testimonial-image" />
            <h2 className="testimonial-name">{area.name}</h2>
            <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>{source}</p>
          </div>
        </div>
})}
    </Slider>:null}
              </div>
              <FishUpload existingFolderId={pcloud_id}/>
          </div>
          <Footer/>
        </div>
        </>
      );

}
export default FishType
