import axios from "axios";
import { API_URL } from "./ServiceAPI";

export const SpaceAPI={
    getLocations:(signal)=>axios.get(`${API_URL}/Space/getAllLocations`,{signal:signal}),
    getLocationData:(location_id)=>axios.get(`${API_URL}/Space/getLocation/${location_id}`),
    getSpaceData:(date,location_id)=>axios.get(`${API_URL}/Space/getSpaceData/${date}/${location_id}`),
    getImage:(file_id)=>axios.post(`${API_URL}/Space/getImage`,{
        file_id: file_id,
      },{
        responseType: 'blob'
      }),
      getLatest: (location_id) => axios.get(`${API_URL}/Space/getNewData/${location_id}`)

}