
function VideoItem({ videoUrl, showVideo }) {
    return showVideo && videoUrl ? (
        <div>
            <h1>Video Player</h1>
            <video controls width="600">
                {/* Correct the API path to match your backend */}
                <source src={videoUrl } type="video/mp4" />

                Your browser does not support the video tag.
            </video>
        </div>
    ) : null;
}

export default VideoItem;
