import React, { useState } from "react";
import "./../forgot.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }
        setIsLoading(true);
        Axios.post("http://localhost:3000/auth/forgot-password", {
            email,
        }).then(response => {
            if (response.data.status) {
                setSuccessMessage("Check your email for the reset password link.");
                setErrorMessage("");
                navigate('/login');
            }
        }).catch(err => {
            console.log(err);
            setErrorMessage("An error occurred. Please try again later.");
            setSuccessMessage("");
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <div className="forgot-password-container">
            <form className="forgot-password-form" onSubmit={handleSubmit}>
                <h2>Forgot Your Password?</h2>
                <p>Please enter your email address below. We'll send you a link to reset your password.</p>

                {successMessage && <p className="success-message">{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
        
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        autoComplete="off"
                        placeholder="Enter your email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send Reset Link'}
                </button>
            </form>
        </div>
    );
}

export default ForgotPassword;