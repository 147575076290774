import { createContext } from "react";
import CryptoJS from "crypto-js"
import { UserAPI } from "../Api/UserAPI";
import axios from "axios";
export const UserContext=createContext()

export const ServiceInfo={
    Satellites: {Name: "Satellites",serviceNumber:10,sqlName:"satellites",sqlNameId:null,  displayName: "Space",display:true,link_url:"/home/satellites",instructionsPath:"Instructions_custom_users.pdf"},
    TableApp: {Name: "TableApp",serviceNumber:0,sqlName:"tableApp",sqlNameId:"tableAppId", displayName: "TableApp",display:true,link_url:"/home/tableapp",instructionsPath:"Instructions_tableapp_users.pdf",after_download_info:["The analysis lasts 1.5 times the length of the video.","You will see the results in the 'My Reports' section after the blue button labeled 'View Report' appears."]},
    FishCounting: {Name: "FishCounting",serviceNumber:1,sqlName:"fishCounting",sqlNameId:"fishCountingId",  displayName: "Fish Counting",display:true,link_url:"/home/fishcounting", instructionsPath:"Instructions_tunacounting_users.pdf"},
    SalmorApp: {Name: "SalmorApp",serviceNumber:2,sqlName:"salmorApp",sqlNameId:"salmorAppId",  displayName: "SALmorApp",display:true,link_url:"/home/salmorapp", instructionsPath:"Instructions_salmorapp_users.pdf"},
    BiomassApp: {Name: "BiomassApp",serviceNumber:3,sqlName:"biomassApp",sqlNameId:"biomassAppId",  displayName: "FishBIOMETRY",display:true,link_url:"/home/biomassapp", instructionsPath:"Instructions_biometry_users.pdf"},
    Waste: {Name: "Waste",serviceNumber:4,sqlName:"foodWaste",sqlNameId:"foodWasteId",  displayName: "FoodWASTE",display:false,link_url:"/home/waste", instructionsPath:"foodwaste_instructions.pdf"},
    Biofouling: {Name: "Biofouling",serviceNumber:5,sqlName:"biofoulingOnNets",sqlNameId:"biofoulingOnNetsId",  displayName: "BioFOULING",display:true,link_url:"/home/biofouling",instructionsPath:"biofouling_instructions.pdf"},
    Cagedetection: {Name: "Cagedetection",serviceNumber:6,sqlName:"cageHolesDetection",sqlNameId:"cageHolesDetectionId",  displayName: "HOLEdetection",display:true,link_url:"/home/cagedetection", instructionsPath:"detection_instructions.pdf"},
    Inspection: {Name: "Inspection",serviceNumber:7,sqlName:"inspectionCoverage",sqlNameId:"inspectionCoverageId",  displayName: "100% Inspection",display:true,link_url:"/home/inspection", instructionsPath:"inspection_instructions.pdf"},
    Mortality: {Name: "Mortality",serviceNumber:8,sqlName:"fishMortality",sqlNameId:"fishMortalityId", displayName: "FishMORTALITY",display:true,link_url:"/home/mortality", instructionsPath:"mortality_instructions.pdf"},
    Custom: {Name: "Custom",serviceNumber:9,sqlName:"customSolutions",sqlNameId:"customSolutionsId",  displayName: "Custom",display:true,link_url:"/home/custom",instructionsPath:"Instructions_custom_users.pdf"},

}



export function sha256(message){
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
}

export function getUserFromToken(token,setUser,navigate,navigateBadToken="/",navigateGoodToken){
    if(token)
        axios.defaults.headers.common['user_authorization'] = `Bearer ${token}`;
        UserAPI.verifyLogin().then((response)=>{
            if(response.data){
                setUser(response.data.user)
                const remMe=localStorage.getItem("rememberMe")==="true"
                
                if (remMe){
                    localStorage.setItem('token',response.data.token)
                }
                else{
                    sessionStorage.setItem('token',response.data.token)
                }
                if(navigateGoodToken)
                        navigate(navigateGoodToken)
            }
        }).catch((err)=>{
            console.log(err)
            setUser(null)
            navigate(navigateBadToken)
        });    
}
