import React from 'react';
import './../Apps.css';
import CustomDragDrop from './CustomDragDrop';
import DragAndDrop from '../DragDrop';

const Custom = ( {name}) => {
    return (
        <DragAndDrop appName={name}/>
    );
};

export default Custom;