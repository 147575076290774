import React, { useEffect } from 'react';
import './../Apps.css';
import DragDrop from './../DragDrop';

const BiomassApp = ({name}) => {

    return (
        <DragDrop appName ={name} />
    );
};

export default BiomassApp;