import axios from "axios"
import { API_URL } from "./ServiceAPI"


export const ReportAPI={

    getReports:()=>{
        return axios.get(`${API_URL}/Report/getReports`)
    },
    getHlsVideo:(id)=>{
        return axios.get(`${API_URL}/Report/getVideo/${id}`)
    },
    getReportFile:(report_data_id,fileType)=>{
        return  axios({
            url: `${API_URL}/Report/getReportFiles/${report_data_id}/${fileType}`, // Your backend endpoint
            method: 'GET',
            responseType: 'blob', // Important to handle binary data
          })
    },
    getVideoDownloadLink:(report_id,setVideoReportProgress)=>{
        return axios.get(`${API_URL}/Report/getDownloadVideoLink/${report_id}`, { responseType: 'blob',onDownloadProgress:(event)=>{setVideoReportProgress(event.progress*100)}})
    }

}