import React from 'react';
import './../Apps.css';
import DragDrop from './/../DragDrop';

const Cagedetection = ({name}) => {
    return (
        <DragDrop appName = {name} />
    );
};

export default Cagedetection;