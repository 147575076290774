import axios from "axios";
import { API_URL } from "./ServiceAPI";

export const UserAPI={
    login:(values)=>{
        return axios.post(API_URL+'/User/login', values)
    },
    verifyLogin:()=>{
        return axios.get(API_URL+"/User/verifyUserToken")
    }
    ,getAllCages:(signal)=>(axios.get(`${API_URL}/User/getAllCages`,{signal:signal}))
    ,getAllOtherCages:(signal)=>(axios.get(`${API_URL}/User/getAllOtherCages`,{signal:signal}))
}