/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useEffect, useContext } from 'react';
import './../../style.css';
import  Header  from './Header';
import  Footer  from './Footer';
import { Outlet } from 'react-router-dom';
import { UserContext, getUserFromToken } from '../../Utilis';
import { useNavigate } from 'react-router-dom';
 
const Front = () => {
    const {user,setUser} = useContext(UserContext)
    const navigate = useNavigate();
   
    const handleLogout = () => {
        localStorage.removeItem('token');
    };
    useEffect(()=>{
        if(!user){
            const token = localStorage.getItem('token') || sessionStorage.getItem('token');
            getUserFromToken(token,setUser,navigate,"/")
        }
    },[])

    return (
        user?<>
        <Header handleLogout={handleLogout}/>
        <div className='app-content'>
                        
                <Outlet/>
                
            </div>
        <Footer />
        </>:null
    );
};
export default Front;
