import React, { useState,useEffect,useContext, useRef } from 'react';
import "./FileUploadCircles.css";
import DragDropBiofouling from './DragDropBiofouling';
import { UserAPI } from '../../../../Api/UserAPI';

const FileUploadCircles = () => {
  const [selectedFiles, setSelectedFiles] = useState({});
  const [activeCircle, setActiveCircle] = useState(null);
  const [cages,setCages]=useState([]);
  const abortController=useRef(null)
  
  useEffect(()=>{
    const fetchAndSplit=async()=>{
      if (abortController.current) abortController.current.abort();
      abortController.current = new AbortController();
      const fetched_cages=await fetchCages()
      if(!fetched_cages)return
      setCages(splitInLocations(fetched_cages))
      
    }
   
    fetchAndSplit()
  },[])
 
  const fetchCages=async ()=>{
    try{
      let data_cages=[]
      const response_all_cages=await UserAPI.getAllCages(abortController.current.signal);
      data_cages=[...data_cages,...response_all_cages.data]
      const response_other_cages=await UserAPI.getAllOtherCages(abortController.current.signal);
      data_cages=[...data_cages,...response_other_cages.data]
      return data_cages
    }catch(error){
      return null
    }
  }
  const splitInLocations=(cages)=>{
    let location_split={}
    Object.values(cages).forEach((value)=>{
      if(!location_split.hasOwnProperty(value.location))
        location_split[value.location]=[]
      location_split[value.location].push(value)
    })

    return location_split

  }
  const handleFileChange = (id, event) => {
    const file = event.target.files[0];
    setSelectedFiles(prevState => ({
      ...prevState,
      [id]: file
    }));
  };

  const handleCircleClick = (cage) => {
    console.log(cage)
    setActiveCircle(cage);
  };

  const closeModal = () => {
    setActiveCircle(null);
  };

  return (
    <div className="file-upload-circles">
      <div>
      <h1 className="header">Choose your cage</h1>
      {Object.keys(cages)?.length>0?Object.keys(cages).map((key)=>
          <div key={key}>       
          <h3 style={{marginTop:"30px",marginBottom:"20px",textDecoration:"underline" }}>{key}</h3>
         <div className="circle-container" >
          {cages[key]?.length>0?cages[key].map((cage,index)=>{

            return<div
            key={index}
            className="circle-wrapper"
            onClick={() => handleCircleClick(cage)}
          >
            <div className="circle dashed-inner"></div>
            <div className="circle solid-middle"><p>{cage.cageName.split(" ")[cage.cageName.split(" ").length-1]}</p></div>
            <div className="circle dashed-outer"></div>
        
            
          </div>

          }):null}
          </div></div>
      ):null}
      <div className="circle-container">
        {cages?.length>0?cages?.map((cage,index)=> 
          
            <div
              key={index}
              className="circle-wrapper"
              onClick={() => handleCircleClick(cage)}
            >
              <div className="circle dashed-inner"></div>
              <div className="circle solid-middle"><p>{cage.cageName.split(" ")[cage.cageName.split(" ").length-1]}</p></div>
              <div className="circle dashed-outer"></div>
          
              
            </div>
          ):null}
         
      </div>
      </div>
    
      {activeCircle !== null && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button onClick={closeModal} className="close-modal">X</button>
            <DragDropBiofouling cageId={activeCircle.Id} cageFolderId={activeCircle.folderId} cageName={activeCircle.cageName} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadCircles;