

export const FishsInfo={
    morski_pas:{title:"Morski psi",
        subtitle:"Primjeri morskih pasa",
    areas:[
        {
          image : '/Ribe/MorskiPasPng/Kostelj.webp',
          name: "Kostelj Dubinac",
        },
       
        {
          image: '/Ribe/MorskiPasPng/Modrulj.webp',
          name: "Pas Modrulj",
        },
        {
          image: '/Ribe/MorskiPasPng/Psina.webp',
          name: "Velika bijela psina",
        },
        {
          image: '/Ribe/MorskiPasPng/Glusac.webp',
          name: "Pas Glušac",
        },
        {
          image: '/Ribe/MorskiPasPng/Cukov.webp',
          name: "Pas Čukov",
        },
        {  
          image: '/Ribe/MorskiPasPng/Zvjezdas.webp',
          name: "Pas Zvjezdaš",
        },
        {  
          image: '/Ribe/MorskiPasPng/Prasac.webp',
          name: "Pas Prasac",
        },
      ],
      source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"10880436926"
    },
    morska_macka:{title:"Morske mačke",
        subtitle:"Primjeri morske mačke",
        areas:[
            {
              image : "/Ribe/MorskeMackePng/Bljedica.webp",
              name: "Bljedica",
            },
            {
              image: '/Ribe/MorskeMackePng/Crnousta.webp',
              name: "Crnousta",
            },
            {
              image: '/Ribe/MorskeMackePng/Mrkulja.webp',
              name: "Mrkulja",
            },
          ],
        source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
        pcloud_id:"10880438735"
      },
    sklat:{
      title:"Sklat",
      subtitle:"Primjeri sklatova",
      areas : [
          {
            image : '/Ribe/SklatPng/Sivac.webp',
            name: "Sivac",
          },
          {
            image: '/Ribe/SklatPng/Zutan.webp',
            name: "Žutan",
          },
        ],
        source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"10880443338"
    },

    razopas:{
        title:"Ražopas",
        subtitle:"Primjeri ražopasa",
        areas : [
            {
                image: '/Ribe/RazopasPng/Razopas.webp',
                name: "Ražopas",
              }
          ],
          source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"10880444818"
    },
    golubovi:{ 
        title:"Golubovi",
        subtitle:"Primjeri golubova",
        areas :[
            {
              image : '/Ribe/GoluboviPng/Cukan.webp',
              name: "Čukan",
            },
            {
              image: '/Ribe/GoluboviPng/Dragorepca.webp',
              name: "Žutuga Dračorepa",
            },
            {
              image: '/Ribe/GoluboviPng/Golub.webp',
              name: "Golub",
            },
            {
              image: '/Ribe/GoluboviPng/Leptirica.webp',
              name: "Leptirica",
            },
            {
              image: '/Ribe/GoluboviPng/Ljubicasta.webp',
              name: "Žutuga Ljubičasta",
            },
            {
              image: '/Ribe/GoluboviPng/Uhan.webp',
              name: "Uhan",
            },
            {
              image: '/Ribe/GoluboviPng/Zutuga.webp',
              name: "Žutuga",
            },
          ],
           source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"10880447268"
    },
    drhtulja:{
        title:"Drhtulja",
        subtitle:"Primjeri drhtulja",
        areas:[
            {
                image : '/Ribe/DrhtuljaPng/Marmorata.webp',
                name: "Drhtulja (T. marmorata)",
              },
              {
                image: '/Ribe/DrhtuljaPng/Nobiliana.webp',
                name: "Drhtulja (T. nobiliana)",
              },
              {
                image: '/Ribe/DrhtuljaPng/Torpedo.webp',
                name: "Drhtulja (T. torpedo)",
              },
        ] ,
        source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"10880448599"
    },
    morski_stakor:{
        title:"Morski štakor",
        subtitle:"Primjer Morskog Štakora",
        areas:[ {
            image: '/Ribe/MorskiStakorPng/MorskiStakor.webp',
            name: "Morski štakor",
          },],
           source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"10880449780"
    },
    raza:{
        title:"Raža",
        subtitle:"Primjeri raža",
        areas:[
            {
                image :'/Ribe/RazaPng/Crnopjega.webp',
                name: "Raža Crnopjega",
              },
              {
                image :'/Ribe/RazaPng/Crnoziga.webp',
                name: "Crnožiga raža",
              },
              {
                image: '/Ribe/RazaPng/Kamenica.webp',
                name: "Raža kamenica",
              },
              {
                image: '/Ribe/RazaPng/Modropjega.webp',
                name: "Raža Modropjega",
              },
              {
                image: '/Ribe/RazaPng/RazaJezinka.webp',
                name: "Raža Ježinka",
              },
              {
                image: '/Ribe/RazaPng/Smeda.webp',
                name: "Raža smeđa",
              },
              {  
                image: '/Ribe/RazaPng/Tuponska.webp',
                name: "Raža Tuponska",
              },
              {  
                image: '/Ribe/RazaPng/Vijosaraka.webp',
                name: "Raža Vijošaraka",
              },
              {  
                image: '/Ribe/RazaPng/VolinaBjelica.webp',
                name: "Raža Volina bjelica",
              },
              {  
                image: '/Ribe/RazaPng/VolinaMrkulja.webp',
                name: "Raža Volina Mrkulja",
              },
            
              {  
                image: '/Ribe/RazaPng/Zvjezdopjega.webp',
                name: "Raža Zvjezdopjega",
              },
        ],
         source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"10880452434"
    },
    kitovi:{
        title:"Kitovi",
        subtitle:"Primjer Kitova",
        areas:[ {
            image : '/Ribe/KitoviPng/Uljestura.webp',
            name: "Ulješura",
          },
          {
            image: '/Ribe/KitoviPng/VelikiKit.webp',
            name: "Veliki kit",
          },
          {
            image : '/Ribe/KitoviPng/BjelogrliDupin.webp',
            name: "Bjelogrli dupin",
          },
          {
            image: '/Ribe/KitoviPng/CrniDubin.webp',
            name: "Crni dupin",
          },
          {
            image: '/Ribe/KitoviPng/DobriDupin.webp',
            name: "Dobri dupin",
          },
          {
            image : '/Ribe/KitoviPng/KrupnozubiDupin.webp',
            name: "Krupnozubi dupin",
          },
          {
            image: '/Ribe/KitoviPng/ObicniDupin.webp',
            name: "Obični dupin",
          },
          {
            image: '/Ribe/KitoviPng/PrugastiDupin.webp',
            name: "Prugasti dupin",
          },],
           source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"11069078234"
    },
    kornjace:{
        title:"Kornjače",
        subtitle:"Primjer kornjača",
        areas:[ {
            image : '/Ribe/KornjacaPng/GlavataZelva.webp',
            name: "Glavata Želva",
          },
          {
            image: '/Ribe/KornjacaPng/SedmeroprugaUsminjaca.webp',
            name: "Sedmeropruga Usminjača",
          },
          {
            image: '/Ribe/KornjacaPng/ZelenaZelva.webp',
            name: "Zelena Želva",
          }],
           source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162',
      pcloud_id:"11069080187"
    },
    sredozemna_medvjednica:{
      title:"Sredozemne medvjedice",
      subtitle:"Primjer Sredozemne medvjednice",
      areas:[ {
        image: '/Ribe/SredozemnaMedvjedenicaPng/Sredozemna.webp',
        name: "Sredozemna medvjednica",
      },],
      source:"",
      pcloud_id:"11069080719"
    },
    komercijalne_vrste:{
      title:"Komercijalne vrste",
      subtitle:"Primjeri komercijalnih vrsta",
      areas:[
        {
          image :'/Ribe/KomercijalnePng/Arbun.webp',
          name: "Arbun",
        },
        
        {
          image: '/Ribe/KomercijalnePng/Bezmek.webp',
          name: "Bezmek",
        },
        {
          image: '/Ribe/KomercijalnePng/Bukva.webp',
          name: "Bukva",
        },
        {
          image: '/Ribe/KomercijalnePng/CipalBatas.webp',
          name: "Cipal Bataš",
        },
        {  
          image: '/Ribe/KomercijalnePng/Fratar.webp',
          name: "Fratar",
        },
        {  
          image: '/Ribe/KomercijalnePng/Gavun.webp',
          name: "Gavun",
        },
        {  
          image:  '/Ribe/KomercijalnePng/GiraOblica.webp',
          name: "Gira Oblica",
        },
        {  
          image: '/Ribe/KomercijalnePng/Gof.webp',
          name: "Gof",
        },
        // {
        //   image :Grdobina,
        //   name: "Grdobina",
        // },
        {
          image: '/Ribe/KomercijalnePng/Incun.webp',
          name: "Inčun",
        },
      
        {
          image: '/Ribe/KomercijalnePng/KokoticBarjaktar.webp',
          name: "Kokotić Barjaktar",
        },
        {
          image: '/Ribe/KomercijalnePng/Komarca.webp',
          name: "Komarča",
        },
        {  
          image: '/Ribe/KomercijalnePng/Kovac.webp',
          name: "Kovač",
        },
        {  
          image: '/Ribe/KomercijalnePng/Lokarda.webp',
          name: "Lokarda",
        },
        {  
          image: '/Ribe/KomercijalnePng/Lubin.webp',
          name: "Lubin",
        },
        {  
          image: '/Ribe/KomercijalnePng/Pagar.webp',
          name: "Pagar",
        },
        {  
          image: '/Ribe/KomercijalnePng/Palamida.webp',
          name: "Palamida",
        },
        {  
          image: '/Ribe/KomercijalnePng/Papalina.webp',
          name: "Papalina",
        },
        {  
          image: '/Ribe/KomercijalnePng/PaukMrkulj.webp',
          name: "Pauk Mrkulj",
        },
        {  
          image: '/Ribe/KomercijalnePng/Pic.webp',
          name: "Pic",
        },
        {  
          image: '/Ribe/KomercijalnePng/Salpa.webp',
          name: "Salpa",
        },
      
        {  
          image: '/Ribe/KomercijalnePng/Skusa.webp',
          name: "Skuša",
         },
        // {  
        //   image: Spar,
        //   name: "Špar",
        // },
        {
          image: '/Ribe/KomercijalnePng/Srdela.webp',
          name: "Srdela",
        },
        // {  
        //   image: Strijelka,
        //   name: "Strijelka",
        // },
        {  
          image: '/Ribe/KomercijalnePng/TrljaBlatarica.webp',
          name: "Trlja Blatarica",
        },
        {  
          image:  '/Ribe/KomercijalnePng/Tunj.webp',
          name: "Tunj",
        },
        {  
          image: '/Ribe/KomercijalnePng/Ugor.webp',
          name: "Ugor",
        },
        {  
          image: '/Ribe/KomercijalnePng/Zubatac.webp',
          name: "Zubatac",
        },
        {  
          image: '/Ribe/KomercijalnePng/ZubatacKrunas.webp',
          name: "Zubatac Krunaš",
        },
      ],
      source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 163-452',
      pcloud_id:"11069082042"
    },
    rijetke_vrste:{
      title:"Rijetke vrste",
      subtitle:"Primjeri rijetkih vrsta",
      areas:[ {
        image: '/Ribe/RijetkePng/Barjaktarka.webp',
        name: "Barjaktarka",
      },
      {
        image: '/Ribe/RijetkePng/Drozd.webp',
        name: "Drozd, Drozak",
      },
      {
        image: '/Ribe/RijetkePng/GolemaPriljepusa.webp',
        name: "Golema Priljepuša",
      },
      {  
        image: '/Ribe/RijetkePng/JadranskaJesetra.webp',
        name: "Jadranska Jesetra ",
      },
      {  
        image: '/Ribe/RijetkePng/Jegulja.webp',
        name: "Jegulja",
      },
      {  
        image: '/Ribe/RijetkePng/KonjicDugokljunas.webp',
        name: "Konjic Dugokljunaš",
      },
    
      {
        image:  '/Ribe/RijetkePng/KonjicKratkokljunas.webp',
        name: "Konjic Kratkokljunaš",
      },
      {
        image:  '/Ribe/RijetkePng/NevjestaPisanica.webp',
        name: "Nevjesta, Pisanica",
      },
      {
        image: '/Ribe/RijetkePng/Pastruga.webp',
        name: "Pastruga",
      },
      {  
        image: '/Ribe/RijetkePng/Obrvan.webp',
        name: "Obrvan",
      },
      {  
        image: '/Ribe/RijetkePng/Pojas.webp',
        name: "Pojas",
      },
      {  
        image: '/Ribe/RijetkePng/Sljuka.webp',
        name: "Šljuka",
      },],
      source:'Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 163-452',
      pcloud_id:"11069083969"
    },
    nepoznate_ribe:{
      title:"Nepoznate i ostale ribe",
      subtitle:"U nepoznate i ostale ribe dodajete ribe za koje niste sigurni u koju kategoriju pripadaju",
      areas:[],
      source:'',
      pcloud_id:"10880453897"
    }

}