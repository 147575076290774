import React from "react";
import './Guide.css';
import temperatureImg from './Guide_Images/temperature.png';
import oxygenImg from './Guide_Images/oxygen.png';
import chlorophyllImg from './Guide_Images/chlorophyll.png';
import salinityImg from './Guide_Images/salinity.png';
import currentImg from './Guide_Images/current.png';

const Guide = () => {
    return (
        <div className="guide">
            <section className="guide-section">
                <div className="guide-content">
                    <img src={temperatureImg} alt="Temperature" className="guide-image" />
                    <div className="guide-text">
                        <h2 className="guide-heading">Temperature info:</h2>
                        <p>
                        Warm water species normally cannot breed at temperatures below 20 °C and cannot grow in temperatures below 10 to 15 °C, while they can handle much lower temperatures in the winter. Coldwater species cannot sustain temperatures that are higher than 20 to 25 °C while temperatures above 32 °C inhibit warm water fish reproduction. Most tropical species do not develop at temperatures below 25 °C, and they cannot endure temperatures of 10 to 20 °C.

                        </p>
                    </div>
                </div>
            </section>
            <section className="guide-section">
                <div className="guide-content">
                    <img src={oxygenImg} alt="Dissolved Oxygen" className="guide-image" />
                    <div className="guide-text">
                        <h2 className="guide-heading">Parts per Million (ppm) - Dissolved Oxygen info:</h2>
                        <p>
                        Insufficient levels of dissolved oxygen will result in fish being compelled to move to colder and deeper water layers, heightening the strain on their bodies, and in severe cases, leading to atypical fish mortality. Below 6 ppm fish can start to experience significant sideffects.
                        </p>
                    </div>
                </div>
            </section>
            <section className="guide-section">
                <div className="guide-content">
                    <img src={chlorophyllImg} alt="Chlorophyll a" className="guide-image" />
                    <div className="guide-text">
                        <h2 className="guide-heading">Ecological Condition by Site (Chlorophyll a) info:</h2>
                        <p>
                        Concentration of chlorophyll a at the surface of a fish farm is essential for describing water quality and predicting incoming algal bloom which is lethal for marine life. Unusual increases in concentration can be used as a warning .

                        </p>
                    </div>
                </div>
            </section>
            <section className="guide-section">
                <div className="guide-content">
                    <img src={salinityImg} alt="Salinity" className="guide-image" />
                    <div className="guide-text">
                        <h2 className="guide-heading">Salinity info:</h2>
                        <p>
                        Hypersalinity (too high seawater salinity) causes fish to struggle with balancing their organism's salt regulation, which, as a consequence, makes digesting food more difficult for the fish (&gt;39 ppt). This can lead to reduced growth and underdevelopment in individual fish. On the other hand, hyposalinity (too low seawater salinity) can lead to organ failure in saltwater species and potentially high fish mortality (&lt;34 ppt).

                        </p>
                    </div>
                </div>
            </section>
            <section className="guide-section">
                <div className="guide-content">
                    <img src={currentImg} alt="Sea Current Velocity" className="guide-image" />
                    <div className="guide-text">
                        <h2 className="guide-heading">Sea Current Velocity</h2>
                        <p>
                        Sufficient sea current velocity ensures the flow of fresh, oxygenaited water in and out of the cage which is relevant for the fish health, also a significant current intensity is needed for fish to exercise and develop properly. On the other hand abnormally strong currents can cause clumping of fish and, depending on the cage, cause instability and various other logistical problems (&gt;15 cm/s).
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Guide;
