import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../Utilis';

const Header = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        const adjustPaddingForHeader = () => {
            const headerHeight = document.querySelector('.sticky-header').offsetHeight;
            const mainContent = document.querySelector('.container');
            if(mainContent) {
                mainContent.style.paddingTop = `${headerHeight}px`;
            }
        };
    
        window.addEventListener('resize', adjustPaddingForHeader);
        adjustPaddingForHeader();
    
        return () => window.removeEventListener('resize', adjustPaddingForHeader);
    }, []); 

    const { user: userProfile, setUser: setUserProfile } = useContext(UserContext);

    const handleLogout = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        window.location.href = '/';
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <>
            <div className="sticky-header">
                <div className="left-section">
                    <button className="menu-btn" onClick={toggleSidebar}>
                        &#9776;
                    </button>
                </div>
                <div className="right-section">
                    <div className="logo">
                        <a href="https://bluedatab.com/" target="_blank" rel="noopener noreferrer">
                            <img src={require('./../../Png/small.webp')} alt="Karmen Studio Logo" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <button className="close-btn" onClick={toggleSidebar}>
                    &times;
                </button>
                <div className="avatar-section">
                    <Link to="/home/apps">
                        <div className="avatar">
                            {userProfile && userProfile.avatar ? (
                                <img src={`data:image/png;base64,${userProfile.avatar}`} alt="User Avatar" />
                            ) : (
                                <h3>Avatar</h3>
                            )}
                        </div>
                    </Link>
                    <div className="yellow-line-new"></div>
                </div>
                <ul>
                    <li>
                        <Link to="/home/myreport/reportlist" className='black-link'>My Reports</Link>
                    </li>
                    <li>
                        <Link to="/home/apps" className='black-link'>Services</Link>
                    </li>
                    <li>
                        <a href="#" className='black-link' onClick={handleLogout}>Log out</a>
                    </li>
                </ul>
            </div>
            {sidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
        </>
    );
};

export default Header;
