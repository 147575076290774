import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import emailIcon from '../../Png/email.webp';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section contact">
                    <a href="https://bluedatab.com/contact/"><h3>Contact</h3></a>
                    <p>
                        <a href="mailto:info@bluedatab.com" style={{ color: 'white' }}>
                            <img src={emailIcon} alt="E-mail" className="social-icon" />
                            <b>info@bluedatab.com</b>
                        </a>
                    </p>            
                    {/* <p className="location">
                        <img src={locationIcon} alt="Location" className="social-icon-l" /> 
                        Ulica Dr. Ante Alfirevica 15, 21212 K. Sucurac, Croatia
                    </p> */}
                </div>

                <div className="footer-section">
                    <h3>Follow us on:</h3>
                    <div className="social-icons">
                        {/* <a href="https://www.facebook.com/KARMENstudioAI" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                            <FontAwesomeIcon icon={faFacebookF} className="fa-icon" />
                        </a>
                        <a href="https://twitter.com/KarmenstudioAI" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                            <FontAwesomeIcon icon={faTwitter} className="fa-icon" />
                        </a> */}
                        <a href="https://www.linkedin.com/in/blue-datab-534a502b8/?originalSubdomain=hr" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                            <FontAwesomeIcon icon={faLinkedin} className="fa-icon" />
                        </a>
                    </div>
                </div>
                <div className="footer-section copyright">
                    bluedatab.com &copy; 2024.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
