import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './Map.css';
import Guide from './Guide';
import current from './Guide_Images/current.png';
import { SpaceAPI } from '../../../../Api/SpaceAPI';
import { useParams } from 'react-router-dom';


const headers = ['', 'Yesterday', 'Prediction Today', 'Prediction Next 2 days'];
const options = { year: 'numeric', month: 'long', day: 'numeric' };
const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
const dayAfterTomorrow = new Date(today);
dayAfterTomorrow.setDate(today.getDate() + 2);

const yesterdayDateString = yesterday.toLocaleDateString(undefined, options);
const todayDateString = today.toLocaleDateString(undefined, options);
const tomorrowDateString = tomorrow.toLocaleDateString(undefined, options);
const dayAfterTomorrowDateString = dayAfterTomorrow.toLocaleDateString(undefined, options);
const subheading = ['Depth', yesterdayDateString, todayDateString, tomorrowDateString, dayAfterTomorrowDateString];
const titles = { "temp": { title: "Temperature", unit: "°C", className: "temperature" }, "o2": { title: "Dissolved oxygen", unit: "ppm", className: "dissolvedOxygen" }, "chl": { title: "Chlorophyll", unit: "μg/L", className: "chlorophyll" }, "so": { title: "Salinity ", unit: "ppt", className: "salinity" } }
const Map = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locationInfo, setLocationInfo] = useState(null)
  const [images, setImages] = useState({});
  const [collapsed, setCollapsed] = useState({
    temperature: false,
    dissolvedOxygen: false,
    chlorophyll: false,
    salinity: false,
  });
  const { locationId } = useParams()
  useEffect(() => {
    if (locationId) {
      setLoading(true);
      fetchLocationData(locationId);
    }
  }, [locationId]);
  const fetchImage = async (file_id) => {
    const response = await SpaceAPI.getImage(file_id);
    const url = URL.createObjectURL(response.data);
    return url;
  };
  const fetchSeaCurrentImages = async (userData) => {
    const newImages = {};
    if (!userData) return
    const seaCurrent = userData.data_json.sea_current;
    for (const date of Object.keys(seaCurrent)) {
      if (date == "Depth") continue
      const currentImages = {};
      for (const timeSlot of Object.keys(seaCurrent[date])) {
        const file_id = seaCurrent[date][timeSlot].pcloud_id;
        const url = await fetchImage(file_id);
        currentImages[timeSlot] = url;
      }
      newImages[date] = currentImages;
    }
    setImages(newImages);
  };
  const fetchLocationData = async (locationId) => {
    try {
      const locationResponse = await SpaceAPI.getLocationData(locationId)
      setLocationInfo(locationResponse.data)
      const dataResponse = await SpaceAPI.getLatest(locationId);
      if (!dataResponse) return
      fetchSeaCurrentImages(dataResponse.data[0])
      setUserData(dataResponse.data[0]);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleCollapse = (table) => {
    setCollapsed(prevState => ({
      ...prevState,
      [table]: !prevState[table],
    }));
  };

  const downloadCSV = () => {
    const allRows = [];

    Object.keys(userData).forEach((key) => {
      const data = userData[key];
      if (data) {
        allRows.push([key.toUpperCase()]);
        allRows.push(data.headers || []);
        allRows.push(data.subheading || []);
        if (data.rows && Array.isArray(data.rows)) {
          data.rows.forEach(row => allRows.push(row));
        }
        allRows.push([]);
      }
    });

    const csvContent = allRows.map(e => (e || []).join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = 'weekly_data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);

    Object.keys(userData).forEach((key, index) => {
      const data = userData[key];
      doc.setFontSize(14);
      doc.setTextColor(40);
      doc.text(key.charAt(0).toUpperCase() + key.slice(1), 10, doc.autoTable.previous ? doc.autoTable.previous.finalY + 10 : 10);

      const tableMargin = 10;
      doc.autoTable({
        head: [data.headers],
        body: data.rows,
        startY: (doc.autoTable.previous ? doc.autoTable.previous.finalY + tableMargin : 20) + 10,
        theme: 'grid',
        headStyles: { fillColor: '#7EB3DA', textColor: '#ffffff' },
        styles: { cellPadding: 3, fontSize: 10 },
        alternateRowStyles: { fillColor: '#f2f2f2' },
      });

      doc.text('', 10, doc.autoTable.previous.finalY + 10);
    });

    doc.save(`weakly_data.pdf`);
  };
  const Table = ({ data, className }) => {
    if (!data) return null;

    const createTable = (data) => {
      if (!data) return null
      const dates = Object.keys(data).filter(key => key !== "Depth").sort((a, b) => new Date(a) - new Date(b));
      const depths = Object.keys(data.Depth);
      const table_data = depths.map((depth_key) => {
        let data_array = []
        if (!isNaN(parseFloat(data.Depth[depth_key]))) {
          data_array.push(`${-data.Depth[depth_key]} m`)
        }
        else {
          data_array.push(data.Depth[depth_key])
        }

        dates.forEach((date) => {
          data_array.push(data[date][depth_key])
        })
        return data_array
      })
      return <table className="data-table">
        <thead>
          <tr>
            <th></th>
            <th style={{ color: '#7EB3DA' }}>YESTERDAY</th>
            <th style={{ color: '#7EB3DA' }}>Prediction Today</th>
            <th colSpan={2} style={{ color: '#99D18B' }}>Prediction Next 2 days</th>
          </tr>
          <tr>
            <th style={{ color: 'inherit' }}>Depth (m)</th>
            {dates.map((date, index) =>
              <th key={index} className="table-subheading" style={{ color: index >= 2 ? "#99D18B" : '#7EB3DA' }}>{date}</th>)}
          </tr>
        </thead>
        <tbody>
          {table_data.map((row_values, index) => {
            return (
              <tr key={row_values}>
                {row_values.map((value, index2) =>
                  <td className="table-cell" style={{ color: index2 == 2 ? "#7EB3DA" : 'inherit' }} key={index2}>{value}</td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    }
    return (
      <div className={`table-wrapper ${className}`}>
        {createTable(data)}
      </div>
    );
  };

  const DataTable = ({ dataKey, data }) => {
    const title=titles[dataKey].title
    const className=`table-${titles[dataKey].className}`
    const unit=titles[dataKey].unit
    return (<div className={`table-holder ${className}`}>
      <h3 className={`table-heading-${titles[dataKey].className}`}>{title} [{unit}]</h3>
      <button onClick={() => toggleCollapse(titles[dataKey].className)} className="collapse-btn">
        {collapsed[dataKey] ? 'Expand' : 'Minimize'} Table ▼
      </button>

      {!collapsed[titles[dataKey].className] && <Table data={data} className={className} />}
    </div>
    )
  };
  if (loading) return <p className="loading">Loading...</p>;

  return (
    <div className="map-container">
      {locationInfo?.name && <h1 style={{ textAlign: "center", marginBottom: "20px", fontWeight: "500" }}>{locationInfo?.name}</h1>}
      <div className="cover-container">
        {locationInfo?.cage_image && <img className='cover-image-space' src={`data:image/png;base64,${locationInfo.cage_image}`} alt="User Avatar" />}
      </div>

      {userData?.data_json && Object.keys(titles).map((data_key, index) => {
        if (!userData?.data_json.hasOwnProperty(data_key)) return null
        return <DataTable key={index} data={userData.data_json[data_key]} dataKey={data_key} />
      })}


      {/* <div className="download-buttons">
        <button onClick={downloadCSV} className="download-btn">
          Last Week data CSV
        </button>
        <button onClick={downloadPDF} className="download-btn">
          Last Week data PDF
        </button>
      </div> */}

      <h2 className="current-data-heading">Sea Current Data</h2>
      <div className="cover-container">
        <img src={current} alt="Sea Current" className="current-image" />
      </div>

      {images && Object.keys(images).map((date, index) => {
        const day = new Date(date);
        const day_string = day.toLocaleDateString(undefined, options)
        return (
          <div key={date} style={{ width: "100%" }} >
            <h2 className="prediction-heading">{index % 2 == 0 ? `Prediction for TODAY` : `Prediction for TOMORROW`}
              <span style={{ display: "block" }}>{day_string}</span>
            </h2>

            <div className='image-row'>
              {Object.entries(images[date]).map(([timeSlot, imgUrl]) => (
                <div key={timeSlot}>
                  <h4>{timeSlot}</h4>
                  <img
                    width="10%"
                    src={imgUrl}
                    alt={`Sea current image for ${timeSlot}`}
                    style={{ display: imgUrl ? 'block' : 'none' }}
                  />
                </div>
              ))}
            </div>
          </div>
        )
      })}
      <Guide />
    </div>
  );
}




export default Map;
