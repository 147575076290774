import React, { useContext, useEffect, useRef, useState } from 'react';
import dragDropImage from '../drag-drop.png'
import { ServiceAPI } from '../../../../Api/ServiceAPI';
import { UserContext } from '../../../../Utilis';
import { ReactComponent as ConfirmIcon } from "../../../../Png/check-solid.svg"
const DragAndDropBiofouling = ({ cageId, cageName,cageFolderId }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploaded,setUploaded]=useState(false)
    const [showConfUploBtn,setShowConfUploBtn]=useState(true)
    const [progressByFile,setFileProgressByFile]=useState({})
    const [fileTypeError, setFileTypeError] = useState('');
    const [dragOver, setDragOver] = useState(false);
    const {user,setUser} = useContext(UserContext);
    const controller = useRef(null);
    const stripFileName=(name)=>{
        let ret_name=name
        ret_name=ret_name.replace("#","")
        return ret_name
    }
    const handleUpload=async ()=>{
        let _accessToken=""
        setUploadProgress(0);
        setUploaded(false)
        controller.current=new AbortController()
        //login
        try{
            const resLogin=await ServiceAPI.login()
            if (resLogin.data.result == 0) {
              _accessToken = resLogin.data.auth;
            }
        }catch(ex){
            console.log(ex)
            return
        }
        //za uzeti iz user-a tocan service folderId
        
        uploadedFiles.forEach((file,index)=>{
            let data={}
            
            setFileProgressByFile(prev=>({...prev,[index.toString()]:0}))
            ServiceAPI.createFolder(_accessToken,cageFolderId,stripFileName(file.name))    
            .then((resFolder)=>{
                if(resFolder.data.result!=0){

                    throw Error(resFolder.data?.error)
                }
            if(resFolder&&resFolder.data.result==0){
                const folderNumber=resFolder.data["metadata"]["folderid"]
                data={...data,folderName:file.name,folderId:folderNumber,serviceNumber:5,karmenId:user.Id,cageId:cageId}
                return ServiceAPI.createService(data)
            }
            })//begin Create Service
            .then((resService)=>{
                if(resService){
                    data={...data,...resService.data}
                    setShowConfUploBtn(false)
                    return ServiceAPI.upload_file(_accessToken,resService.data.folderId,file,setFileProgressByFile,index,controller.current.signal)
                }})
            .then((resUpload)=>{
                if(resUpload){
                    data={...data,videoId:resUpload.data["metadata"][0]["fileid"],videoName:resUpload.data["metadata"][0]["name"]}
                    return ServiceAPI.createVideoUpload(data)
                }})
            .then((resVideoUpload)=>{
                setFileProgressByFile(prev=>({...prev,[index.toString()]:100}))
            })
        .catch((ex)=>{
            setFileProgressByFile(prev=>({...prev,[index.toString()]:-1}))           
            setFileTypeError("Cant create folder")
            console.log(ex)
        })
    })
    }

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(true);
        
        const files = event.dataTransfer.files;
        const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime','video/webm'];
        const newFiles = [];
        if(user)
        for (let i = 0; i < files.length; i++) {
            if (acceptedTypes.includes(files[i].type)&&!uploadedFiles.find((element)=>element.name==files[i].name)) {
                newFiles.push(files[i]);
            } else {
                setFileTypeError(`${files[i].name} is not a supported file type.`);
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    };
    const handleFileSelect=(event)=>{
        event.preventDefault();
        const files = event.target.files
        const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime','video/webm'];
        const newFiles = [];
        if(user)
        for (let i = 0; i < files.length; i++) {
            if (acceptedTypes.includes(files[i].type)&&!uploadedFiles.find((element)=>element.name==files[i].name)) {
                newFiles.push(files[i]);
            } else {
                setFileTypeError(`${files[i].name} is not a supported file type.`);
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    }
    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
      };
    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
        event.dataTransfer.dropEffect = 'copy';
    };
    const handleClearUploadedFiles = () => {
        setUploadedFiles([]);
        setFileTypeError('');
        setUploaded(false)
        setShowConfUploBtn(true)
        setUploadProgress(0)
        setFileProgressByFile({})
        if(controller.current)
            if (!controller.current.signal.aborted) {
                controller.current.abort();
            }
    };
    const handleConfirmUpload = (file) => {
        
        handleUpload();
    };

    const handleFileRemove=(index)=>{
        const newFiles=uploadedFiles.filter((files,i)=>i!==index)
        setUploadedFiles([...newFiles])
    }
    useEffect(()=>{
        let count=0;
        let totalPersernt=0;
        Object.entries(progressByFile).forEach((entry)=>{
            if(entry[1]==-1)
                return
            totalPersernt+=entry[1]
            count+=1
        })
        const progress=Number(totalPersernt/count)
        setUploadProgress(progress)
        if(progress>=100){
            setUploaded(true)
            setUploadProgress(0)
            setShowConfUploBtn(true)
            setUploadedFiles([])
        }
    },[progressByFile])

    return (
        <div className={`container ${dragOver ? 'drag-over' : ''}`}>
            <h1 onClick={()=>window.open(`/home/biofoulingCageReport/${cageId}`,"_blank")} style={{cursor:"pointer"}}>{cageName}</h1>
            <p style={{cursor:"pointer"}} onClick={()=>window.open(`/home/biofoulingCageReport/${cageId}`,"_blank")}>Click here for historical reports of this cage</p>
            <div className="drag-drop-container" onDrop={handleDrop} onDragOver={handleDragOver}>
                <div className="drag-drop-area">
                    <img src={dragDropImage} alt="Drag and drop" className="drag-drop-image" />
                    <p><b>Drag and drop files here</b></p>
                    <p className="drag-drop-text"><b>or</b></p>
                    <div>
                        <button className='fileselect-btn' onClick={handleButtonClick}>Select files</button>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            accept="video/mp4, video/avi, video/quicktime, video/webm"
                            multiple
                            onChange={handleFileSelect}
                        />
                    </div>
                </div>
            </div>
            {uploadedFiles.length>0 ?<>
            <div style={{ margin:"5px",backgroundColor:"#DAC625", padding:"10px",borderRadius:"10px"}} className="uploaded-files">
                <p style={{fontSize:"20px"}}>Selected files</p>
                 {uploadedFiles.map((file, index) => (
                    <div key={index} style={{display:"flex",flexDirection:"row",alignItems:"center"}}><p style={{fontWeight:"700",marginRight:"5px",cursor:"default"}}>Name: </p><p style={{fontSize:"16px",marginRight:"5px",cursor:"default"}}> {file.name}</p>{progressByFile.hasOwnProperty(index)?<span style={{fontWeight:"700"}}><img style={{paddingLeft:"5px"}} width={"15px"} src={require("../../../../Png/upload.webp")}/>{progressByFile[index]==-1?"Already exists":`${progressByFile[index]} %`}</span>:<img style={{cursor:"pointer"}} src={require("../../../../Png/delete.webp")} height={"16px"} onClick={()=>handleFileRemove(index)}/>}</div>
                ))}
            </div> 
            {showConfUploBtn?<button className='confirm' onClick={handleUpload}>Upload selected files</button>:null}
            </>:null}
                <div className="upload-progress-container">
                {uploadProgress > 0||!showConfUploBtn ?<> <progress value={uploadProgress} max="100"></progress>
                    <span>{uploadProgress.toFixed(2)}%</span></>:null}
                    {uploaded?<><p className="upload-done-info">Upload completed<ConfirmIcon className="icon" /></p>

                   
                </>:null}
                </div>            
            <div>
                {fileTypeError && <p className="error">{fileTypeError}</p>}
            </div>
            <p>For historical data of cage, press on the cage name</p>
            {/* {appName===ServiceInfo.TableApp.Name?<Camera setVideoFile={(value)=>setCameraVideoFile(value)} />:null} */}
        </div>
    );
};
export default DragAndDropBiofouling;
