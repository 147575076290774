import React from 'react';
import './../Apps.css';
import DragDrop from './../DragDrop';

const SalmorApp = ({name}) => {
    return (
        <DragDrop appName ={name} />
    );
};

export default SalmorApp;