import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import './login.css';
import Validation from './LoginValidation/Validation';
import { UserContext, getUserFromToken, sha256 } from '../../Utilis';
import { UserAPI } from '../../Api/UserAPI';
import axios from 'axios';
function LoginPage() {
    const [values, setValues] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [backendError, setBackendError] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const handleInput = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const err = Validation(values);
        setErrors(err);
        if (err.email === '' && err.password === '') {
            setLoading(true);
            let valuesForSubmit = { ...values };
            valuesForSubmit.password = sha256(valuesForSubmit.password);
            UserAPI.login(valuesForSubmit).then((res) => {
                if (res.data.id !== 0 && res.data) {
                    localStorage.setItem("rememberMe",rememberMe)
                    if (rememberMe) {
                        localStorage.setItem('token', res.data.token);
                    } else {
                        sessionStorage.setItem('token', res.data.token);
                    }
                    axios.defaults.headers.common['user_authorization'] = `Bearer ${res.data.token}`;
                    setUser(res.data.user);
                    navigate('/home/apps');
                } else {
                    setBackendError('No record exists');
                }
            }).catch((error) => {
                console.error('An error occurred:', error);
                setBackendError('An error occurred. Please try again later.');
            }).finally(() => {
                setLoading(false);
            });
        }
    };
    const handleLogout = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        delete axios.defaults.headers.common['user_authorization'];
        setUser(null);
        navigate('/');
    };
    useEffect(() => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (token) {
            getUserFromToken(token, setUser, navigate, "/", "/home/apps");
        }
    }, [setUser, navigate]);
    if (user) {
        return (
            <div>
                <button onClick={handleLogout}>Logout</button>
            </div>
        );
    }
    return (
                <div className='login-page'>
                <div className="heading-container">
                    <a href='https://bluedatab.com/' target='_blank' rel='noopener noreferrer'>
                        <img src="/bluedatab-logo.png" alt="BlueDataB Logo" className="logo-bluedatab" />
                    </a>
                    <h1 className="heading">Welcome to </h1>
                    <h2 className="sub-heading">MY.BlueDataB</h2>
                    <div className="yellow-line"></div>
                    <h3 className='sub-heading-p'>Please, Log in to access your personalized space for <a href='https://bluedatab.com/'>BLUEDATAB</a></h3>
                    <h3 className='sub-heading-p'>services, where your data and reports are securely managed</h3>
                </div>
                    <div className="login-container">
                <div className="login-box">
                    <h2>Secure Client Login</h2>
                    {backendError && <p className="error-message">{backendError}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <div className="input-container">
                                <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
                                <input
                                    type="email"
                                    placeholder='Email'
                                    name='email'
                                    value={values.email}
                                    onChange={handleInput}
                                    className="input-field"
                                />
                                {errors.email && <span className='error-message'>{errors.email}</span>}
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="password-input-container">
                                <FontAwesomeIcon icon={faLock} className="input-icon" />
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder='Password'
                                    name='password'
                                    value={values.password}
                                    onChange={handleInput}
                                    className="password-input-field"
                                />
                                <button type="button" onClick={() => setShowPassword(!showPassword)} className="toggle-password">
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                                {errors.password && <span className='error-message'>{errors.password}</span>}
                            </div>
                        </div>
                        <div className='remember'>
                            <input
                                type="checkbox"
                                id="rememberMe"
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                            />
                            <label htmlFor="rememberMe">Remember me</label>
                        </div>
                        <button type="submit" className="btn" disabled={loading}>
                            {loading ? "Logging in..." : "Log in"}
                        </button>
                    </form>
                    <p className='sub-heading-f'>*For new users, please <a href="mailto:contact@bluedatab.com">contact support</a> to set up your account.</p>
                </div>
            </div>
            {/* <div>
                <p className="baza-text">
                    *Molimo vas pomozite nam popuniti bazu slika ugroženih<b><Link to='ribe'> Riba Jadranskog mora </Link></b>
                </p>
                <p className="baza-text">
                    *Please help us fill the database of endangered fish of <b><Link to='ribe'>Adriatic Sea Fish</Link></b>
                </p>
                <Link to='ribe'>
                    <button className="baza-button">Baza Riba Jadranskog Mora</button>
                </Link>
            </div> */}
            <p>Learn more about our services on our website:</p>
             <a href="https://bluedatab.com/services" target="_blank" rel="noopener noreferrer">
                <img src="/services.png" alt="BlueDataB Services" className="services-image" />
            </a>
        </div>
    );
}
export default LoginPage;