/* eslint-disable no-unused-vars */
import React from 'react';
import './../Apps.css';
import DragDrop from './../DragDrop';

const Inspection = ({name}) => {
    return (
        <DragDrop appName = {name} />
    );
};

export default Inspection;