import axios from "axios"
import { API_URL } from "./ServiceAPI"


export const HeatmapAPI={
    getReportImage:(folder_id,img_name,signal)=> axios.post(`${API_URL}/Heatmap/getReportImage`, {
        folderId: folder_id,
        imgName: img_name
      },
        {
          signal:signal,
          responseType: 'blob'
        }),
    getCage: (cageId)=>axios.get(`${API_URL}/Heatmap/getCage/${cageId}`),
    getReportData:(report_id,signal)=>  axios.get(`${API_URL}/Heatmap/getReportData/${report_id}`, {
        signal:signal
      })

}