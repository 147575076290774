import React from 'react';
import '../Home/Apps/Apps.css'
import "./ribe.css"
import "./vrste.css"
import Vrste from './Vrste';
const SveVrste = ({name}) => {
    return (
        <Vrste/>
    );
};

export default SveVrste;